<template>
  <StoreMonthlySales />
</template>

<script>
import StoreMonthlySales from '@/components/pages/sales/StoreMonthlySales'

export default {
  components: {
    StoreMonthlySales
  }
}
</script>
